import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingIcon from '@mui/icons-material/Pending';
import SpeedIcon from '@mui/icons-material/Speed';
import SupportIcon from '@mui/icons-material/Support';
import InvertColorsRounded from '@mui/icons-material/InvertColorsRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';

import { useTranslation } from './LocalizationProvider';
import RemoveDialog from './RemoveDialog';
import PositionValue from './PositionValue';
import { useDeviceReadonly } from '../util/permissions';
import { devicesActions } from '../../store';
import { useCatch, useCatchCallback } from '../../reactHelper';

const useStyles = makeStyles((theme) => ({
  card: {
    pointerEvents: 'auto',
    width: theme.dimensions.popupMaxWidth,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 0),
    background: '#74A9F6',
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: 'gray',
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
      borderCollapse: 'separate',
      borderSpacing: '0px 4px',
    },
  },
  cell: {
    maxWidth: 70,
    maxheight: '5',
    margintop: '20',
    background: '#454545',
    padding: 8,
    paddingRight: 5,
    borderCollapse: 'separate',
    border: 1,
    borderRight: '4px solid gray',
    borderLeft: '4px solid gray',
    borderRadius: '10px',
  },
  cell2: {
    maxheight: '5',
    margintop: '20',
    background: 'gray',
    padding: 8,
    paddingRight: 5,
    border: 0,
  },
  actions: {
    justifyContent: 'space-between',
  },
  root: ({ desktopPadding }) => ({
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: 5,
    left: '50%',
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-75%)',
  }),
}));

const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => state.devices.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  const handleGeofence = useCatchCallback(async () => {
    const newItem = {
      name: '',
      area: `CIRCLE (${position.latitude} ${position.longitude}, 50)`,
    };
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      const permissionResponse = await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deviceId: position.deviceId, geofenceId: item.id }),
      });
      if (!permissionResponse.ok) {
        throw Error(await permissionResponse.text());
      }
      navigate(`/settings/geofence/${item.id}`);
    } else {
      throw Error(await response.text());
    }
  }, [navigate, position]);

  return (
    <>
      <div className={classes.root}>
        {device && (
          <Draggable
            handle={`.${classes.media}, .${classes.header}`}
          >
            <Card elevation={3} className={classes.card}>
              {deviceImage ? (
                <div>
                  <Typography variant="body2" sx={{ border: '1', paddingLeft: 2, color: 'white', fontWeight: 500, fontstyle: 'oblique', fontSize: 16, background: '#74A9F6' }}>
                    {device.name}
                  </Typography>
                  <CardMedia
                    className={classes.media}
                    image={`/api/media/${device.uniqueId}/${deviceImage}`}
                  >
                    <IconButton
                      size="small"
                      onClick={onClose}
                      onTouchStart={onClose}
                    >
                      <CloseIcon fontSize="small" className={classes.mediaButton} />
                    </IconButton>
                  </CardMedia>
                </div>
              ) : (
                <div className={classes.header}>
                  <Typography variant="body2" sx={{ border: '1', paddingLeft: 2, color: 'white', fontWeight: 500, fontstyle: 'oblique', fontSize: 16 }}>
                    {device.name}
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={onClose}
                    onTouchStart={onClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              )}
              {position && (
                <CardContent className={classes.content}>
                  <Typography inline align="center" sx={{ color: 'white', fontSize: '9px', maxWidth: '100%', backgroundColor: '#454545' }}>
                    Details as of :
                    <PositionValue
                      position={position}
                      property="fixTime"
                      attribute={null}
                    />
                  </Typography>
                  <Table classes={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.cell2} colSpan={3}>
                          <Typography inline align="left" sx={{ color: 'white', fontSize: '9px', maxWidth: '180px', display: 'flex' }}>
                            <PublicRoundedIcon sx={{ color: 'white', fontSize: '16px', minWidth: '25px', paddingRight: '10px' }} />
                            <PositionValue
                              position={position}
                              property="address"
                              attribute={null}
                            />
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          <Typography sx={{ color: 'skyblue', fontSize: '9px', minWidth: '40px', maxWidth: '80px' }}>
                            CURRENT SPEED
                          </Typography>
                          <Typography inline align="right" sx={{ color: 'white', fontSize: '9px', maxWidth: '80px', display: 'flex' }}>
                            <PositionValue
                              position={position}
                              property="speed"
                              attribute={null}
                            />
                            <SpeedIcon sx={{ color: 'skyblue', fontSize: '16px', minWidth: '25px', paddingLeft: '10px' }} />
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography sx={{ color: 'yellow', fontSize: '9px', minWidth: '40px', maxWidth: '80px' }}>
                            ODOMETER
                          </Typography>
                          <Typography inline align="right" sx={{ color: 'white', fontSize: '9px', maxWidth: '80px', display: 'flex' }}>
                            <PositionValue
                              position={position}
                              property={null}
                              attribute="odometer"
                            />
                            <SupportIcon sx={{ color: 'yellow', fontSize: '16px', minWidth: '25px', paddingLeft: '10px' }} />
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography sx={{ color: '#FF3131', fontSize: '9px', minWidth: '40px', maxWidth: '90px' }}>
                            FUEL VOLUME
                          </Typography>
                          <Typography inline align="right" sx={{ color: 'white', fontSize: '9px', maxWidth: '90px', display: 'flex' }}>
                            <PositionValue
                              position={position}
                              property={null}
                              attribute="fuel"
                            />
                            <InvertColorsRounded sx={{ color: '#FF3131', fontSize: '16px', minWidth: '25px', paddingLeft: '8px' }} />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              )}
              <CardActions classes={{ root: classes.actions }} disableSpacing sx={{ maxheight: '20px', height: '50px' }}>
                <IconButton
                  color="secondary"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  disabled={!position}
                  sx={{ margin: 0 }}
                >
                  <Typography sx={{ color: 'black', fontSize: '9px', minWidth: '20px', maxWidth: '30px' }}>
                    EXTRA
                    <PendingIcon sx={{ color: '#33D1FF', fontSize: '25px', minWidth: '25px' }} />
                  </Typography>
                </IconButton>
                <IconButton
                  onClick={() => navigate('/replay')}
                  disabled={disableActions || !position}
                >
                  <Typography sx={{ color: 'black', fontSize: '9px', minWidth: '20px', maxWidth: '30px' }}>
                    REPLAY
                    <ReplayIcon sx={{ color: 'purple', fontSize: '25px', minWidth: '25px' }} />
                  </Typography>
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/settings/command-send/${deviceId}`)}
                  disabled={disableActions}
                >
                  <Typography sx={{ color: 'black', fontSize: '9px', minWidth: '20px', maxWidth: '30px' }}>
                    COMMAND
                    <PublishIcon sx={{ color: '#16DCA6', fontSize: '25px', minWidth: '25px' }} />
                  </Typography>
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/settings/device/${deviceId}`)}
                  disabled={disableActions || deviceReadonly}
                >
                  <Typography sx={{ color: 'black', fontSize: '9px', minWidth: '20px', maxWidth: '30px' }}>
                    MODIFY
                    <EditIcon sx={{ color: '#D30EF9', fontSize: '25px', minWidth: '25px' }} />
                  </Typography>
                </IconButton>
                <IconButton
                  onClick={() => setRemoving(true)}
                  disabled={disableActions || deviceReadonly}
                  className={classes.negative}
                >
                  <Typography sx={{ color: 'black', fontSize: '9px', minWidth: '20px', maxWidth: '30px' }}>
                    DELETE
                    <DeleteIcon sx={{ color: '#C04000', fontSize: '25px', minWidth: '25px' }} />
                  </Typography>
                </IconButton>
              </CardActions>
            </Card>
          </Draggable>
        )}
      </div>
      {position && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}><Typography color="secondary">{t('sharedShowDetails')}</Typography></MenuItem>
          <MenuItem onClick={handleGeofence}>{t('sharedCreateGeofence')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}>{t('linkGoogleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}>{t('linkAppleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}>{t('linkStreetView')}</MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </>
  );
};

export default StatusCard;
