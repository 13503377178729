export default {
  sidebarWidth: '28%',
  sidebarWidthTablet: '52px',
  drawerWidthDesktop: '500px',
  drawerWidthTablet: '320px',
  drawerHeightPhone: '250px',
  filterFormWidth: '160px',
  eventsDrawerWidth: '320px',
  bottomBarHeight: 56,
  popupMapOffset: 300,
  popupMaxWidth: '150%',
  popupImageHeight: 100,
  drawerWidthDesktopRS: '280px', // Added for report setting
};
