import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Paper, Menu, MenuItem, Typography, Badge, Tabs,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ReplayIcon from '@mui/icons-material/Replay';
import DevicesIcon from '@mui/icons-material/Devices';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RadarIcon from '@mui/icons-material/Radar';
import PeopleIcon from '@mui/icons-material/People';
import Tab from '@mui/material/Tab';
import { sessionActions } from '../../store';
import { useTranslation } from './LocalizationProvider';
import { useRestriction } from '../util/permissions';
import { nativePostMessage } from './NativeInterface';
import './Header.css';
import logoimage from '../../resources/images/justtracklogo.jpg';

const TopMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const t = useTranslation();
  const readonly = useRestriction('readonly');
  const disableReports = useRestriction('disableReports');
  const user = useSelector((state) => state.session.user);
  const socket = useSelector((state) => state.session.socket);

  const [anchorEl, setAnchorEl] = useState(null);

  const currentSelection = () => {
    if (location.pathname === `/settings/user/${user.id}`) {
      return 'account';
    } if (location.pathname.startsWith('/settings/devices')) {
      return 'devices';
    } if (location.pathname.startsWith('/settings/users')) {
      return 'users';
    } if (location.pathname.startsWith('/settings')) {
      return 'settings';
    } if (location.pathname.startsWith('/reports')) {
      return 'reports';
    } if (location.pathname.startsWith('/replay')) {
      return 'replay';
    } if (location.pathname.startsWith('/dashboard')) {
      return 'dashboard';
    } if (location.pathname.startsWith('/geofences')) {
      return 'geofences';
    } if (location.pathname === '/') {
      return 'Maps';
    } if (location.pathname.startsWith('/other')) {
      return 'other';
    }
    return null;
  };

  const handleAccount = () => {
    setAnchorEl(null);
    navigate(`/settings/user/${user.id}`);
  };

  const handleLogout = async () => {
    setAnchorEl(null);

    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken) {
      window.localStorage.removeItem('notificationToken');
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: tokens.length > 1 ? tokens.filter((it) => it !== notificationToken).join(',') : undefined,
          },
        };
        await fetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }

    await fetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    navigate('/login');
    dispatch(sessionActions.updateUser(null));
  };
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const handleSelection = (event, value) => {
    switch (value) {
      case 'Maps':
        navigate('/');
        break;
      case 'reports':
        navigate('/reports/route');
        break;
      case 'settings':
        navigate('/settings/preferences');
        break;
      case 'account':
        handleAccount();
        // setAnchorEl(event.currentTarget);
        break;
      case 'logout':
        handleLogout();
        break;
      case 'replay':
        navigate('/replay');
        break;
      case 'devices':
        navigate('/settings/devices');
        break;
      case 'users':
        navigate('/settings/users');
        break;
      case 'dashboard':
        if (desktop) {
          navigate('/dashboard');
        }
        if (!desktop) {
          navigate('/dashboardMobile');
        }
        break;
      case 'geofences':
        navigate('/geofences');
        break;
      default:
        break;
    }
  };

  return (
    <Paper square elevation={3}>
      <Tabs
        sx={{
          height: 10, background: 'linear-gradient(to right, blue , red)', color: 'white',
        }}
        value={currentSelection()}
        onChange={handleSelection}
        textColor="white"
        variant="scrollable"
        TabIndicatorProps={{
          style: {
            backgroundColor: 'orange',
          },
        }}
        showLabels
      >
        <div style={{ width: 40, paddingTop: 5, paddingLeft: 8, fontFamily: 'cursive', letterSpacing: '.1rem' }}>
          <img alt="justTrack" src={logoimage} height="35" />
        </div>
        <div style={{ width: 120, paddingTop: 8, fontFamily: 'cursive', Weight: 900, letterSpacing: '.1rem' }}>
          <strong style={{ fontSize: 20, paddingLeft: 15, fontfamily: 'cursive' }}> JustTrack </strong>
        </div>
        <Tab label={t('sharedDashboard')} icon={<DashboardIcon />} iconPosition="start" value="dashboard" sx={{ color: 'white', paddingBottom: 4, paddingLeft: 5 }} />
        <Tab
          icon={(
            <Badge sx={{ color: 'white' }} variant="dot" overlap="circular" invisible={socket !== false}>
              <MapIcon />
            </Badge>
          )}
          sx={{ color: 'white', paddingBottom: 4 }}
          iconPosition="start"
          label={t('sharedPatrol')}
          value="Maps"
          /* Tulsi: was originally map */
        />
        <Tab label={t('reportReplay')} icon={<ReplayIcon />} iconPosition="start" value="replay" sx={{ color: 'white', paddingBottom: 4 }} />
        {!disableReports && (
          <Tab label={t('reportTitle')} icon={<DescriptionIcon />} iconPosition="start" value="reports" sx={{ color: 'white', paddingBottom: 4 }} />
        )}
        <Tab label={t('settingsTitle')} icon={<SettingsIcon />} iconPosition="start" value="settings" sx={{ color: 'white', paddingBottom: 4 }} />
        <Tab label={t('sharedDevice')} icon={<DevicesIcon />} iconPosition="start" value="devices" sx={{ color: 'white', paddingBottom: 4 }} />
        <Tab label={t('sharedGeofences')} icon={<RadarIcon />} iconPosition="start" value="geofences" sx={{ color: 'white', paddingBottom: 4 }} />
        {!readonly && (
          <Tab label={t('settingsUsers')} icon={<PeopleIcon />} iconPosition="start" value="users" sx={{ color: 'white', paddingBottom: 4 }} />
        )}
        {!readonly && (
          <Tab label={user.name} icon={<PersonIcon />} iconPosition="start" value="account" sx={{ color: 'white', paddingBottom: 4 }} />
        )}
        <Tab label={t('loginLogout')} icon={<ExitToAppIcon />} iconPosition="start" value="logout" sx={{ color: 'white', paddingBottom: 4 }} />
      </Tabs>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleAccount}>
          <Typography color="textPrimary">{t('settingsUser')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Typography color="error">{t('loginLogout')}</Typography>
        </MenuItem>
      </Menu>
    </Paper>
  );
};

export default TopMenu;
