import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  IconButton, Tooltip, Avatar, ListItemAvatar, ListItemText, ListItemButton,
} from '@mui/material';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import ErrorIcon from '@mui/icons-material/Error';
import SatelliteIcon from '@mui/icons-material/SatelliteAlt';
import PowerIcon from '@mui/icons-material/Power';
import DriverIcon from '@mui/icons-material/PersonPin';
import MotionPhotosAutoIcon from '@mui/icons-material/MotionPhotosAuto';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { devicesActions } from '../store';
import {
  formatAlarm, formatBoolean, formatPercentage, formatStatus, getStatusColor, formatMotion,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { useAdministrator } from '../common/util/permissions';
import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import { useAttributePreference } from '../common/util/preferences';
import { useEffectAsync } from '../reactHelper';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '12px',
    height: '12px',
    filter: 'brightness(0) invert(1)',
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
}));

const DeviceRow = ({ data, index }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const navigate = useNavigate();
  const admin = useAdministrator();

  const item = data[index];
  const position = useSelector((state) => state.session.positions[item.id]);

  const geofences = useSelector((state) => state.geofences.items);

  const devicePrimary = useAttributePreference('devicePrimary', 'name');
  const deviceSecondary = useAttributePreference('deviceSecondary', '');

  const formatProperty = (key) => {
    if (key === 'geofenceIds') {
      const geofenceIds = item[key] || [];
      return geofenceIds
        .filter((id) => geofences.hasOwnProperty(id))
        .map((id) => geofences[id].name)
        .join(', ');
    }
    return item[key];
  };
  // Code to get Driver
  const [driver, setDriver] = useState([]);

  useEffectAsync(async () => {
    const response = await fetch(`/api/drivers?deviceId=${item.id}`);
    if (response.ok) {
      setDriver(await response.json());
    } else {
      throw Error(await response.text());
    }
  }, []);

  // LINK TO DRIVER ON CLICK
  const handleEdit = () => {
    navigate(`${'/settings/driver'}/${`${driver.map((item) => (item.id))}`}`);
  };
  const secondaryText = () => {
    let status;
    if (item.status === 'online' || !item.lastUpdate) {
      status = formatStatus(item.status, t);
    } else {
      status = moment(item.lastUpdate).fromNow();
    }
    return (
      <>
        {deviceSecondary && item[deviceSecondary] && `${formatProperty(deviceSecondary)} • `}
        <span className={classes[getStatusColor(item.status)]}>{status}</span>
      </>
    );
  };

  return (
    <div>
      <ListItemButton
        key={item.id}
        onClick={() => dispatch(devicesActions.selectId(item.id))}
        disabled={!admin && item.disabled}
        sx={{ width: '100%', borderTop: 2, borderColor: 'lightblue', m: 0, p: 0, pb: 0, backgroundColor: 'white' }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'lightblue', width: 25, height: 25, marginLeft: 1 }}>
            <img className={classes.icon} src={mapIcons[mapIconKey(item.category)]} alt="" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={formatProperty(devicePrimary)}
          primaryTypographyProps={{ noWrap: true, fontSize: 12, color: 'black' }}
          secondary={secondaryText()}
          secondaryTypographyProps={{ noWrap: true, fontSize: 12 }}
          sx={{ width: '100%', m: 0, p: 0, pb: 0 }}
        />
        {position && (
          <>
            {position.attributes.hasOwnProperty('alarm') && (
              <Tooltip title={`${t('eventAlarm')}: ${formatAlarm(position.attributes.alarm, t)}`}>
                <IconButton size="small" sx={{ borderLeft: 1, borderColor: 'gray' }}>
                  <ErrorIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${driver.map((item) => (item.name))}`}>
                <IconButton size="small" sx={{ borderLeft: 1, borderColor: 'gray' }} onClick={handleEdit}>
                  <DriverIcon fontSize="small" className={classes.negative} />
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('ignition') && (
              <Tooltip title={`${t('positionIgnition')}: ${formatBoolean(position.attributes.ignition, t)}`}>
                <IconButton size="small" sx={{ borderLeft: 1, borderColor: 'gray' }}>
                  {position.attributes.ignition ? (
                    <EngineIcon fontSize="small" width={20} height={20} className={classes.positive} />
                  ) : (
                    <EngineIcon fontSize="small" width={20} height={20} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('battery') && (
              <Tooltip title={`${t('positionBatteryLevel')}: ${formatPercentage(position.attributes.battery)}`}>
                <IconButton size="small" sx={{ borderLeft: 1, borderColor: 'gray' }}>
                  {position.attributes.batteryLevel > 70 ? (
                    position.attributes.charge
                      ? (<BatteryChargingFullIcon fontSize="small" className={classes.positive} />)
                      : (<BatteryFullIcon fontSize="small" className={classes.positive} />)
                  ) : position.attributes.batteryLevel > 30 ? (
                    position.attributes.charge
                      ? (<BatteryCharging60Icon fontSize="small" className={classes.medium} />)
                      : (<Battery60Icon fontSize="small" className={classes.medium} />)
                  ) : (
                    position.attributes.charge
                      ? (<BatteryCharging20Icon fontSize="small" className={classes.negative} />)
                      : (<Battery20Icon fontSize="small" className={classes.negative} />)
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('sat') && (
              <Tooltip title={`${t('positionSatVisible')}: ${position.attributes.sat}`}>
                <IconButton size="small" sx={{ borderLeft: 1, borderColor: 'gray' }}>
                  {position.attributes.sat ? (
                    <SatelliteIcon fontSize="small" width={10} height={10} className={classes.medium} />
                  ) : (
                    <SatelliteIcon fontSize="small" width={10} height={10} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('power') && (
              <Tooltip title={`${t('positionPower')}: ${position.attributes.power}`}>
                <IconButton size="small" sx={{ borderLeft: 1, borderColor: 'gray' }}>
                  {position.attributes.power ? (
                    <PowerIcon fontSize="small" width={20} height={20} className={classes.positive} />
                  ) : (
                    <PowerIcon fontSize="small" width={20} height={20} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {position.attributes.hasOwnProperty('motion') && (
              <Tooltip title={`${t('positionMotion')}: ${formatMotion(position.attributes.motion, t)}`}>
                <IconButton size="small" sx={{ borderLeft: 1, borderColor: 'gray' }}>
                  {position.attributes.motion ? (
                    <MotionPhotosAutoIcon fontSize="small" width={20} height={20} className={classes.positive} />
                  ) : (
                    <MotionPhotosAutoIcon fontSize="small" width={20} height={20} className={classes.neutral} />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </ListItemButton>
    </div>
  );
};

export default DeviceRow;
