import React, {
  useState, useEffect, useRef,
} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { PieChart, Pie, Cell, Legend, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip, ResponsiveContainer } from 'recharts';
import {
  Card,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
} from '@mui/material';
import TopMenu from '../common/components/TopMenu';
import { useEffectAsync } from '../reactHelper';
import TableShimmer from '../common/components/TableShimmer';
import { filterByKeyword } from '../settings/components/SearchHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  header: {
    pointerEvents: 'auto',
    zIndex: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'sticky',
    width: '100%',
    overflowY: 'auto',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    zIndex: 3,
    left: 0,
    top: 48,
    margin: theme.spacing(0),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  slider: {
    width: '100%',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControlLabel: {
    height: '100%',
    width: '100%',
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  card: {
    pointerEvents: 'auto',
    width: 364,
    height: 400,
    padding: 10,
    overflowY: 'auto',
    background: 'linear-gradient(to bottom, white , orange)',
  },
  cell: {
    borderBottom: '0',
    borderLeft: '0',
    borderRight: '0',
    borderColor: 'lightblue',
    borderRightColor: 'RED',
    color: 'blue',
    fontSize: '12px',
    margintop: '0',
    padding: 8,
  },
  tablerow: {
    border: '0',
    lineHeight: 0,
    backgroundColor: '#F5F5F5',
  },
  mobileRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const ReplayPage = () => {
  const classes = useStyles();
  const timerRef = useRef();

  const [positions] = useState([]);
  const [index, setIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [itemsPos, setPosItems] = useState([]);
  const [searchKeyword] = useState('');
  const data = [
    { name: 'Group A', value: 'false' },
    { name: 'Group B', value: 'false' },
    { name: 'Group C', value: 'false' },
    { name: 'Group D', value: 'true' },
  ];
  // TablePagination starts
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(8);

  const handleChangePage = (event, newPage) => {
    setpg(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  };
  // TablePagination ends
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  // code for count of devices status
  const DeviceStatuses = items
    .map((dataItem) => dataItem.status) // get all media types
    .filter((DeviceStatus, index, array) => array.indexOf(DeviceStatus) === index); // filter out duplicates

  const DeviceStatusList = DeviceStatuses
    .map((DeviceStatus) => ({
      type: DeviceStatus,
      count: items.filter((item) => item.status === DeviceStatus).length,
    }));
  const DeviceMotions = itemsPos
    .map((dataItem) => dataItem.attributes.motion) // get all media types
    .filter((DeviceMotion, index, array) => array.indexOf(DeviceMotion) === index); // filter out duplicates

  const DeviceMotionList = DeviceMotions
    .map((DeviceMotion) => ({
      type: DeviceMotion,
      count: itemsPos.filter((item) => item.attributes.motion === DeviceMotion).length,
      motionstate: DeviceMotion === true ? 'Motion' : 'Stationery',
    }));
  useEffect(() => {
    if (playing && positions.length > 0) {
      timerRef.current = setInterval(() => {
        setIndex((index) => index + 1);
      }, 500);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [playing, positions]);

  useEffect(() => {
    if (index >= positions.length - 1) {
      clearInterval(timerRef.current);
      setPlaying(false);
    }
  }, [index, positions]);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/positions');
      if (response.ok) {
        setPosItems(await response.json());
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <TopMenu />
        </div>
        <Table>
          <TableRow className={classes.tablerow}>
            <TableCell className={classes.cell}>
              <Card elevation={3} className={classes.card}>
                <Typography variant="subtitle1">
                  Device List
                </Typography>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: '1', color: 'blue', fontSize: '12px', margintop: 0, lineHeight: 0 }}>ID </TableCell>
                    <TableCell sx={{ border: '1', color: 'blue', fontSize: '12px', margintop: 0, lineHeight: 0, width: 150 }}>Name</TableCell>
                    <TableCell sx={{ border: '1', color: 'blue', fontSize: '12px', margintop: 0, lineHeight: 0 }}>IMEI No</TableCell>
                    <TableCell sx={{ border: '1', color: 'blue', fontSize: '12px', margintop: 0, lineHeight: 0 }}>Phone</TableCell>
                    <TableCell sx={{ border: '1', color: 'blue', fontSize: '12px', margintop: 0, lineHeight: 0 }}>Status</TableCell>
                  </TableRow>
                  {!loading ? items.filter(filterByKeyword(searchKeyword)).slice(pg * rpg, pg * rpg + rpg).map((item) => (
                    <TableRow key={item.id}>
                      <TableCell sx={{ border: '1', color: 'gray', fontSize: '9px', paddingTop: 0, paddingBottom: 0, lineHeight: 0 }}>{item.id}</TableCell>
                      <TableCell sx={{ border: '1', color: 'gray', fontSize: '9px', paddingTop: 0, paddingBottom: 0, lineHeight: 2, width: 150 }}>{item.name}</TableCell>
                      <TableCell sx={{ border: '1', color: 'gray', fontSize: '9px', paddingTop: 0, paddingBottom: 0, lineHeight: 0 }}>{item.uniqueId}</TableCell>
                      <TableCell sx={{ border: '1', color: 'gray', fontSize: '9px', paddingTop: 0, paddingBottom: 0, lineHeight: 0 }}>{item.phone}</TableCell>
                      <TableCell sx={{ border: '1', color: 'gray', fontSize: '9px', paddingTop: 0, paddingBottom: 0, lineHeight: 0 }}>{item.status}</TableCell>
                    </TableRow>
                  )) : (<TableShimmer columns={6} endAction />)}
                </TableBody>
                <TablePagination
                  rowsPerPageOptions={[7]}
                  component="div"
                  count={items.length}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tablerow}>
            <TableCell className={classes.cell}>
              <Card elevation={3} className={classes.card}>
                <Typography variant="subtitle1">
                  Connection State
                </Typography>
                <PieChart width={500} height={350}>
                  <Legend verticalAlign="top" height={36} />
                  <Pie
                    data={DeviceStatusList}
                    cx="35%"
                    cy="40%"
                    innerRadius={30}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="count"
                    nameKey="type"
                    label="type"
                  >
                    {data.map((entry, index) => (
                      <Cell fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </Card>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tablerow}>
            <TableCell className={classes.cell}>
              <Card elevaton={3} className={classes.card}>
                <Typography variant="subtitle1">
                  Motion State
                </Typography>
                {/* <p>{items.map((item) => (item.name))}</p>
                <p>{itemsPos.map((item) => (item.attributes.motion === false ? 1 : 2))}</p>
                <p>{DeviceMotionList.map((item) => (item.count))}</p>
                <p>{DeviceMotionList.map((item) => (item.type))}</p> */}
                {items.length > 0 && (
                <p>
                  <PieChart width={500} height={320}>
                    <Legend verticalAlign="top" height={36} />
                    <Pie data={DeviceMotionList} dataKey="count" nameKey="motionstate" cx="35%" cy="40%" outerRadius={80} fill="#8884d8" label="type" />
                    {/* <Pie data={DeviceMotionList} dataKey="count" nameKey="type" cx="20%" cy="30%" innerRadius={60} outerRadius={80} fill="#82ca9d" label="type" /> */}
                  </PieChart>
                </p>
                )}
              </Card>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tablerow}>
            <TableCell className={classes.cell}>
              <Card elevation={3} className={classes.card}>
                <Typography variant="subtitle1">
                  Current Stats
                </Typography>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    width={500}
                    height={300}
                    data={itemsPos}
                    margin={{
                      top: 15,
                      right: 20,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="1 1 1" />
                    <XAxis dataKey="deviceId" label={{ value: 'Device', offset: 1, position: 'insideBottom' }} />
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={20} align="right" />
                    <Bar dataKey="attributes.sat" fill="orange" barSize={20} />
                    <Bar dataKey="attributes.power" fill="blue" barSize={20} />
                    <Bar dataKey="attributes.battery" fill="red" barSize={20} />
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tablerow}>
            <TableCell className={classes.cell}>
              <Card elevation={3} className={classes.card}>
                <Typography variant="subtitle1">
                  Current Speed
                </Typography>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    width={500}
                    height={300}
                    data={itemsPos}
                    margin={{
                      top: 15,
                      right: 0,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="deviceId" label={{ value: 'Device', offset: 1, position: 'insideBottom' }} />
                    <YAxis label={{ value: 'Speed', angle: -90, offset: 15, position: 'insideLeft' }} />
                    <Tooltip />
                    <Legend verticalAlign="top" height={20} align="right" />
                    <Bar dataKey="speed" fill="#8884d8" barSize={20} />
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tablerow}>
            <TableCell className={classes.cell}>
              <Card elevation={3} className={classes.card}>
                <Typography variant="subtitle1">
                  Current Fuel
                </Typography>
                <ResponsiveContainer width="100%" height="90%">
                  <BarChart
                    width={500}
                    height={300}
                    data={itemsPos}
                    margin={{
                      top: 15,
                      right: 20,
                      left: 5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="8" />
                    <XAxis dataKey="deviceId" label={{ value: 'Device', offset: 1, position: 'insideBottom' }} />
                    <YAxis label={{ value: 'Fuel', offset: 15, angle: -90, position: 'insideLeft' }} />
                    <Tooltip />
                    <Legend verticalAlign="top" height={20} align="right" />
                    <Bar dataKey="attributes.fuel" nameKey="fuel" fill="#82ca9d" barSize={20} label="fuel" />
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </div>
  );
};

export default ReplayPage;
