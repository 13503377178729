import React, { useState } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import {
  CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend,
} from 'recharts';
import ReportFilter from './components/ReportFilter';
import { formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayoutRS from '../common/components/PageLayoutRS';
import ReportsMenu from './components/ReportsMenu';
import usePositionAttributesChart from '../common/attributes/usePositionAttributesChart';
import { useCatch } from '../reactHelper';
import { useAttributePreference, usePreference } from '../common/util/preferences';
import {
  altitudeFromMeters, distanceFromMeters, speedFromKnots, volumeFromLiters,
} from '../common/util/converter';
import useReportStyles from './common/useReportStyles';
import TopMenu from '../common/components/TopMenu';

const ChartReportPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();

  const positionAttributes = usePositionAttributesChart(t);

  const distanceUnit = useAttributePreference('distanceUnit');
  const altitudeUnit = useAttributePreference('altitudeUnit');
  const speedUnit = useAttributePreference('speedUnit');
  const volumeUnit = useAttributePreference('volumeUnit');
  const hours12 = usePreference('twelveHourFormat');

  const [items, setItems] = useState([]);
  const [type, setType] = useState('speed');

  const values = items.map((it) => it[type]);
  const minValue = Math.min(...values.filter(Number)); // TO GET RIF OF NaN on fuel report
  const maxValue = Math.max(...values.filter(Number));
  const valueRange = maxValue - minValue;

  const spvalues = items.map((sp) => sp.speed);
  const spmaxValue = Math.max(...spvalues);

  const handleSubmit = useCatch(async ({ deviceId, from, to }) => {
    const query = new URLSearchParams({ deviceId, from, to });
    const response = await fetch(`/api/reports/route?${query.toString()}`, {
      headers: { Accept: 'application/json' },
    });
    if (response.ok) {
      const positions = await response.json();
      const formattedPositions = positions.map((position) => {
        const data = { ...position, ...position.attributes };
        const formatted = {};
        formatted.fixTime = formatTime(position.fixTime, 'datetime', hours12);
        Object.keys(data).forEach((key) => {
          const value = data[key];
          if (typeof value === 'number') {
            const definition = positionAttributes[key] || {};
            switch (definition.dataType) {
              case 'speed':
                formatted[key] = speedFromKnots(value, speedUnit).toFixed(2);
                break;
              case 'altitude':
                formatted[key] = altitudeFromMeters(value, altitudeUnit).toFixed(2);
                break;
              case 'distance':
                formatted[key] = distanceFromMeters(value, distanceUnit).toFixed(2);
                break;
              case 'volume':
                formatted[key] = volumeFromLiters(value, volumeUnit).toFixed(2);
                break;
              case 'hours':
                formatted[key] = (value / 1000).toFixed(2);
                break;
              default:
                formatted[key] = value;
                break;
            }
          }
        });
        return formatted;
      });
      setItems(formattedPositions);
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <div>
      <div>
        <TopMenu />
      </div>
      <PageLayoutRS menu={<ReportsMenu />} breadcrumbs={['reportTitle', 'reportChart']}>
        <div className={classes.containerMain}>
          <div className={classes.header1}>
            <ReportFilter handleSubmit={handleSubmit} showOnly>
              <div className={classes.filterItem}>
                <FormControl fullWidth>
                  <InputLabel>{t('reportChartType')}</InputLabel>
                  <Select label={t('reportChartType')} value={type} onChange={(e) => setType(e.target.value)}>
                    {Object.keys(positionAttributes).filter((key) => positionAttributes[key].type === 'number').map((key) => (
                      <MenuItem key={key} value={key}>{positionAttributes[key].name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </ReportFilter>
          </div>
          {items.length > 0 && (
            <div className={classes.chart}>
              <ResponsiveContainer>
                <LineChart
                  data={items}
                  margin={{
                    top: 10, right: 40, left: 10, bottom: 10,
                  }}
                >
                  <XAxis dataKey="fixTime" tick={{ fontSize: '12', width: '10px' }} />
                  <YAxis yAxisId="left" type="number" tickFormatter={(value) => value.toFixed(2)} domain={[minValue - 5, maxValue + valueRange / 5]} />
                  <YAxis yAxisId="right" orientation="right" type="number" tickFormatter={(value) => value.toFixed(2)} domain={[0, spmaxValue + 5]} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip formatter={(value, key) => [value, positionAttributes[key].tooltipname]} />
                  <Legend />
                  <Line yAxisId="left" type="monotone" dataKey={type} stroke="#8884d8" activeDot={{ r: 8 }} dot={false} />
                  <Line yAxisId="right" type="monotone" dataKey="speed" stroke="#82ca9d" dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </PageLayoutRS>
    </div>
  );
};

export default ChartReportPage;
