import { useMemo } from 'react';

export default (t) => useMemo(() => ({
  fuel: {
    name: t('positionFuelSpeed'),
    tooltipname: 'Fuel',
    type: 'number',
    dataType: 'volume',
  },
  speed: {
    name: '',
    tooltipname: 'Speed',
    type: 'number',
    dataType: 'speed',
    property: true,
  },
  fixTime: {
    name: t('positionFixTime'),
    type: 'string',
    property: true,
  },
  type: {
    name: t('sharedType'),
    type: 'string',
  },
}), [t]);
